@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #1E2933;
    --color-bg-variant: #2D5A60;
    --color-primary: #72CBD5;
    --color-primary-variant: #51A3AD;
    --color-white: #FFFFFF;
    --color-white-variant: #FDF3D0;
    --color-light: rgba(255, 255, 255, 0.6);
    --color-secondary: #D3915F;
    --color-secondary-variant: #CA7B3F;
    --color-secondary-dark: #AD662F;

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

/* Scrollbar Styles */
html {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: var(--dark-slate) var(--navy);
}
::-webkit-scrollbar {
    width: 12px;
    height: 100px;
    /* border: 1px solid #cccccc; */
    display: initial;
}
::-webkit-scrollbar-track {
    background: var(--color-bg-variant);
    display: initial;
}
::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 6px;
    border: 1px solid var(--color-bg-variant);
    display: initial;
}


body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    /* background-image: url(../src/assets/image_placeholder.jpg); */
}

/****** GENERAL STYLES ******/
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}
.App .openModalBtn {
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 6px;
    background-color: cornflowerblue;
    color: white;
    cursor: pointer;
}

/****** MEDIA QUERIES (Medium Devices) ******/
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }
    section {
        margin-top: 6rem;
    }
}

/****** MEDIA QUERIES (Small Devices) ******/
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }
    section > h2 {
        margin-bottom: 2rem;
    }
    ::-webkit-scrollbar {
        display: none;
    }
    ::-webkit-scrollbar-track {
        display: none;
    }
    ::-webkit-scrollbar-thumb {
        display: none;
    }
}