header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    --wave-transition: all 2s linear;
    --wave-animation: move-wave 2s linear 0.4s infinite normal forwards;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/****** CTA ******/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/****** HEADER SOCIALS ******/
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary) ;
}

/***** ME ******/
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.sunset__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 100%;
    /* width: 50rem; */
    /* left: calc(50% - 11rem); */
    background: var(--color-white-variant);
    height: 25rem;
    margin: 3rem 0;
}
.sunset {
    background: linear-gradient(var(--color-secondary), transparent);
    width: 22rem;
    height: 22rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem;
    overflow: hidden;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}
.sunset:hover {
    background: linear-gradient(var(--color-secondary-variant), transparent);
    transition: var(--transition);
}
.sunset__text {
    position: absolute;
    left: calc(50% - 4rem);
    margin-top: 11rem;
    /* color: white; */
}
.sunset__text a {
    color: var(--color-white-variant);
    transition: var(--transition);
    text-shadow: 1px 1px 2px #1E2933;
}
.sunset__text a:hover {
    color: var(--color-white);
    text-shadow: 2px 2px 3px #1E2933;
}
.water {
    background: linear-gradient(var(--color-primary), var(--color-primary-variant));
    /* width: 50rem; */
    width: 100%;
    height: 10rem;
    position: absolute;
    margin: 15rem auto;
    /* left: calc(50% - 11rem); */
    overflow: hidden;
    /* padding: 1.5rem; */
}

.wave_width_1 { width: 200px; }
.wave_width_2 { width: 250px; }
.wave_width_3 { width: 300px; }
.wave_width_4 { width: 350px; }
.wave_width_5 { width: 400px; }
.wave_width_6 { width: 450px; }
.wave_width_1:hover { width: calc(30%); }
.wave_width_2:hover { width: calc(20%); }
.wave_width_3:hover { width: calc(15%); }
.wave_width_4:hover { width: calc(10%); }
.wave_width_5:hover { width: calc(5%); }
.wave_width_6:hover { width: calc(5%); }

.wave_height_1 { height: 8px; }
.wave_height_2 { height: 10px; }
.wave_height_3 { height: 12px; }
.wave_height_4 { height: 16px; }
.wave_height_5 { height: 18px; }
.wave_height_6 { height: 20px; }
.wave_height_1:hover { height: calc(-5%); }
.wave_height_2:hover { height: calc(-10%); }
.wave_height_3:hover { height: calc(-15%); }
.wave_height_4:hover { height: calc(-20%); }
.wave_height_5:hover { height: calc(-25%); }
.wave_height_6:hover { height: calc(-30%); }

.wave_location_1 { margin: 0 auto 0; float: center; }
.wave_location_2 { margin: 0 auto 0; float: center; }
.wave_location_3 { margin: 0 auto 0; float: left; }
.wave_location_4 { margin: 0 auto 0; float: left; }
.wave_location_5 { margin: 0 auto 0; float: right; }
.wave_location_6 { margin: 0 auto 0; float: right; }
.wave_location_1:hover { margin: 0 auto 0; float: center; }
.wave_location_2:hover { margin: 0 auto 0; float: right; }
.wave_location_3:hover { margin: 0 auto 0; float: left; }
.wave_location_4:hover { margin: 0 auto 0; float: center; }
.wave_location_5:hover { margin: 0 auto 0; float: right; }
.wave_location_6:hover { margin: 0 auto 0; float: center; }

.wave_colors {
    --color-bg: #1E2933;
    --color-bg-variant: #2D5A60;
    --color-primary: #72CBD5;
    --color-primary-variant: #51A3AD;
    --color-white: #FFFFFF;
    --color-white-variant: #FDF3D0;
    --color-light: rgba(255, 255, 255, 0.6);
    --color-secondary: #D3915F;
    --color-secondary-variant: #CA7B3F;
    --color-secondary-dark: #AD662F;
}
.wave_color_1{ background-color: var(--color-bg); transition: var(--wave-transition); }
.wave_color_2{ background-color: var(--color-bg-variant); transition: var(--wave-transition); }
.wave_color_3{ background-color: var(--color-light); transition: var(--wave-transition); }
.wave_color_4{ background-color: var(--color-secondary); transition: var(--wave-transition); }
.wave_color_5{ background-color: var(--color-secondary-variant); transition: var(--wave-transition); }
.wave_color_6{ background-color: var(--color-secondary-dark); transition: var(--wave-transition); }
.wave_color_1:hover { background-color: var(--color-bg-variant); }
.wave_color_2:hover { background-color: var(--color-light); }
.wave_color_3:hover { background-color: var(--color-secondary); }
.wave_color_4:hover { background-color: var(--color-secondary-variant); }
.wave_color_5:hover { background-color: var(--color-secondary-dark); }
.wave_color_6:hover { background-color: var(--color-bg); }

/* @keyframes move-wave {
    0% {

    }
    50% {
        
    }
} */


/****** SCROLL DOWN ******/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/****** MEDIA QUERIES (Medium Devices) ******/
@media screen and (max-width: 1024px) {
    header {
        /* height: 68vh; */
        height: 100%;
    }
}

/****** MEDIA QUERIES (Small Devices) ******/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header__socials, .scroll__down {
        display: none;
    }
}